import React, { useContext } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../../../Widget.st.css';
import SelectedLayout from '../SelectedLayout';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { AppContext } from '../../SettingsProvider/SettingsProvider';

const QuestionsResultsFound = ({
  questionsList,
}: {
  questionsList: QuestionEntry[];
}) => {
  const { searchTerm } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className={st(classes.Body, { resultFound: !!searchTerm })}>
      {searchTerm && (
        <div className={st(classes.resultFound)}>
          <Text>{t('widget.search.tab.results.found', { searchTerm })}</Text>
        </div>
      )}
      <SelectedLayout filteredQuestions={questionsList} />
    </div>
  );
};

export default QuestionsResultsFound;
