import React from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import ListOneColumn from './ListOneColumn';

const ListTwoColumns = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const leftQuestions = filteredQuestions.filter(
    (item, index) => index % 2 === 0,
  );
  const rightQuestions = filteredQuestions.filter(
    (item, index) => index % 2 === 1,
  );
  return (
    <>
      <ListOneColumn filteredQuestions={leftQuestions} />
      <ListOneColumn filteredQuestions={rightQuestions} />
    </>
  );
};

export default ListTwoColumns;
