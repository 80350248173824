import {
  ChevronDown,
  ChevronUp,
  Minus,
  Plus,
  SortDown,
  SortUp,
  CirclePlus,
  CircleClose,
  ArrowTopRight,
  ArrowBottomLeft,
  EyeClosed,
  Visible,
} from '@wix/wix-ui-icons-common/on-stage';
import { OpenQuestionIcon } from '../../settingsParams';

export const openIconBySettings = (
  openQuestionIcon: OpenQuestionIcon = OpenQuestionIcon.ARROW,
) => {
  let icons = { expanded: ChevronUp, collapsed: ChevronDown };
  switch (openQuestionIcon) {
    case OpenQuestionIcon.PLUS:
    case OpenQuestionIcon.MINUS:
      icons = { expanded: Minus, collapsed: Plus };
      break;
    case OpenQuestionIcon.ARROWUP:
      icons = { expanded: SortUp, collapsed: SortDown };
      break;
    case OpenQuestionIcon.PLUSCIRCLE:
      icons = { expanded: CircleClose, collapsed: CirclePlus };
      break;
    case OpenQuestionIcon.ARROWUPRIGHT:
      icons = { expanded: ArrowTopRight, collapsed: ArrowBottomLeft };
      break;
    case OpenQuestionIcon.EYEICON:
      icons = { expanded: Visible, collapsed: EyeClosed };
      break;
    case OpenQuestionIcon.ARROW:
    default:
      icons = { expanded: ChevronUp, collapsed: ChevronDown };
      break;
  }
  return icons;
};
