import React from 'react';
import { Text, useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import { st, classes } from './styles.st.css';

import { st as stNumber, classes as classesNumber } from './numbered.st.css';

const AccordionNumberStrip = ({ index }: { index: number }) => {
  const { mobile } = useTPAComponentsContext();
  const formattedIndex = (index + 1).toString().padStart(2, '0');
  return (
    <div className={st(classes.numberwrapper, { mobile })}>
      <Text className={stNumber(classesNumber.number, { mobile })}>
        {formattedIndex}
      </Text>
    </div>
  );
};

export default AccordionNumberStrip;
