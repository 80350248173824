import React, { useContext } from 'react';
import { TabItem, Tabs, TabsAlignment, TabsSkin } from 'wix-ui-tpa/cssVars';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { st, classes } from '../../Widget.st.css';
import { AppContext } from '../SettingsProvider/SettingsProvider';

const HorizontalTabs = ({
  categoriesToRender,
  activeTab,
  adjustedActiveTab,
  setActiveTab,
  splitIntoCategories,
  rtl,
}: {
  categoriesToRender: Category[];
  activeTab: number;
  adjustedActiveTab: number;
  setActiveTab: (index: number) => void;
  splitIntoCategories: boolean;
  rtl: boolean;
}) => {
  const { searchTerm } = useContext(AppContext);
  return (
    splitIntoCategories &&
    categoriesToRender.length > 0 && (
      <Tabs
        data-hook="faq-tabs"
        className={st(classes.Tabs,{
          search: !(searchTerm === ''),
        })}
        items={categoriesToRender as TabItem[]}
        activeTabIndex={adjustedActiveTab}
        onTabClick={setActiveTab}
        skin={TabsSkin.clear}
        alignment={rtl ? TabsAlignment.right : TabsAlignment.left}
      />
    )
  );
};

export default HorizontalTabs;
