import { useState, useEffect, useCallback } from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { FAQService } from '../utils/FAQService';

interface UsePaginatedQuestionsProps {
  api: FAQService;
  selectedCategory?: string | (string | null | undefined)[] | null | undefined;
  searchTerm?: string;
  setQuestionsList: React.Dispatch<React.SetStateAction<QuestionEntry[]>>;
  bottomRef: React.RefObject<HTMLDivElement>;
}
export interface PaginationState {
  cursor: string | null;
  loading: boolean;
  hasMore: boolean;
}

export const usePaginatedQuestions = ({
  api,
  selectedCategory,
  searchTerm,
  setQuestionsList,
  bottomRef,
}: UsePaginatedQuestionsProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    cursor: null,
    loading: false,
    hasMore: false,
  });

  const [searchResultsState, setSearchResultsState] = useState<PaginationState>(
    {
      cursor: null,
      loading: false,
      hasMore: false,
    },
  );

  const fetchInitialQuestions = useCallback(async () => {
    setPagination((prev) => ({ ...prev, loading: true }));

    try {
      if (searchTerm) {
        setSearchResultsState((prev) => ({ ...prev, loading: true }));
        const response = await api.getSearchTerm({ term: searchTerm });
        setQuestionsList(response.questionEntries || []);
        setSearchResultsState({
          cursor: response.pagingMetadata?.cursors?.next || null,
          loading: false,
          hasMore: response.pagingMetadata?.hasNext || false,
        });
      } else {
        const response = await api.getListQuestions({
          categoryId: selectedCategory,
        });
        setQuestionsList(response.questionEntries || []);
        setPagination({
          cursor: response.pagingMetadata?.cursors?.next || null,
          loading: false,
          hasMore: response.pagingMetadata?.hasNext || false,
        });
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setPagination((prev) => ({ ...prev, loading: false }));
      setSearchResultsState((prev) => ({ ...prev, loading: true }));
    }
  }, [api, selectedCategory, searchTerm, setQuestionsList]);

  const loadMoreQuestions = useCallback(async () => {
    if (!pagination.cursor || pagination.loading || !pagination.hasMore) {
      return;
    }

    setPagination((prev) => ({ ...prev, loading: true }));

    try {
      const response = await api.getListQuestions({
        cursor: pagination.cursor,
      });
      setQuestionsList((prev) => [
        ...prev,
        ...(response.questionEntries || []),
      ]);
      setPagination({
        cursor: response.pagingMetadata?.cursors?.next || null,
        loading: false,
        hasMore: response.pagingMetadata?.hasNext || false,
      });
    } catch (error) {
      console.error('Error loading more questions:', error);
      setPagination((prev) => ({ ...prev, loading: false }));
    }
  }, [api, pagination, setQuestionsList]);

  useEffect(() => {
    fetchInitialQuestions();
  }, [fetchInitialQuestions]);

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const rootMarginValue = `${viewportHeight * 0.6}px`;
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !pagination.loading &&
          pagination.hasMore
        ) {
          loadMoreQuestions();
        }
      },
      { rootMargin: rootMarginValue },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [pagination.loading, pagination.hasMore, loadMoreQuestions, bottomRef]);

  return {
    searchResultsState,
    setSearchResultsState,
  };
};
