import React from 'react';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import {
  AccordionTheme,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams, {
  ItemAppearance,
  LayoutType,
} from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';

const VerticalTabs = ({
  categoriesToRender,
  activeTab,
  setActiveTab,
  sideCategoriesPosition,
}: {
  categoriesToRender: Category[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  sideCategoriesPosition: boolean;
}) => {
  const settings: ISettingsContextValue = useSettings();
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const layout: LayoutType = settings.get(settingsParams.layout);
  const numberLayout = layout === LayoutType.Stripes;
  const theme = numberLayout ? AccordionTheme.Box : itemAppearance;
  return (
    <div
      className={st(classes.Tabs, {
        isSideCategoriesPosition: sideCategoriesPosition,
        theme,
      })}
    >
      {categoriesToRender.map((category, index) => {
        return (
          <TextButton
            priority={
              activeTab === index
                ? TextButtonPriority.link
                : TextButtonPriority.secondary
            }
            className={st(classes.underlineTextColor, {
              isLink: activeTab === index,
            })}
            onClick={setActiveTab.bind(null, index)}
          >
            {category.title}
          </TextButton>
        );
      })}
    </div>
  );
};

export default VerticalTabs;
