import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa/cssVars';
import settingsParams from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { st, classes } from '../../Widget.st.css';
import { st as headerStyle, classes as headerClasses } from './header.st.css';
import constants from '../../../../../constants';
import { CategoriesPosition } from '../../../../../enums';

const HeaderWrapper = () => {
  const settings: ISettingsContextValue = useSettings();
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const topCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.TOP;
  const { text, show, alignment } = settings.get(settingsParams.title);

  return (
    <>
      {show && (
        <div
          className={st(classes.title, {
            isTopCategoriesPosition: topCategoriesPosition,
            alignment,
          })}
        >
          <Text
            className={headerStyle(headerClasses.title)}
            typography={TextTypography.largeTitle}
          >
            {text}
          </Text>
        </div>
      )}
    </>
  );
};

export default HeaderWrapper;
