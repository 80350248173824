import React from 'react';
import { st, classes } from '../../Widget.st.css';
import { Structure } from '../../../../../enums';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../../settingsParams';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import ListOneColumn from './ListOneColumn';

import ListTwoColumns from './ListTwoColumns';
import { AccordionCSS } from './Interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ListWrapper = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const settings: ISettingsContextValue = useSettings();
  const { isMobile } = useEnvironment();

  const structureColumn: Structure = settings.get(settingsParams.structure);
  const twoColumnStructure: boolean =
    structureColumn === Structure.TWO_COLUMNS && !isMobile;
  const { gap, betweencols } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-gap': `${gap - 12}px`,
    '--column-gap': `${betweencols}px`,
  } as AccordionCSS;
  return (
    <div
      style={customStyle}
      className={st(
        classes.Body,
        { isTwoColumn: twoColumnStructure },
        classes.listLayout,
      )}
    >
      {twoColumnStructure ? (
        <ListTwoColumns filteredQuestions={filteredQuestions} />
      ) : (
        <ListOneColumn filteredQuestions={filteredQuestions} />
      )}
    </div>
  );
};

export default ListWrapper;
